import { ENABLE_PASSWORD_RESET_BUTTON, INVALID_TOKEN, SHOW_PASSWORD_RESET_MODAL, TOKEN } from './types';

const initialState = {
  showModal: false,
  submitEnabled: true,
  token: undefined,
  invalidToken: null,
  email: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_PASSWORD_RESET_MODAL: {
      const { showModal, email } = payload;
      return { ...state, showModal, email };
    }
    case ENABLE_PASSWORD_RESET_BUTTON: {
      const { submitEnabled } = payload;
      return { ...state, submitEnabled };
    }
    case INVALID_TOKEN: {
      const { invalidToken } = payload;
      return { ...state, invalidToken };
    }
    case TOKEN: {
      const { token } = payload;
      return { ...state, token };
    }
    default: {
      return state;
    }
  }
};
