/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DailyCreditType, UserState } from './types';

const initialState: UserState = {
  logged: false,
  userId: null,
  createdAt: null,
  sessionChecked: false,
  // This will be for Viewers
  subscription: {
    active: null,
    validUntil: null,
  },
  email: null,
  username: null,
  // Valid: [guest, user, vip, partner, host, admin]
  // Default value will be Guest once we go live
  role: null,
  // This needs to be the zooId ONLY for Partners
  // IT needs to be undefined for Hosts
  zooId: 'torontozoo',
  // This needs to be list of habitats that a Host can stream to
  // Should be undefined for Hosts
  habitats: [],
  favoriteHabitats: [],
  // used to store icon, color,
  // and other UI user settings
  profile: {
    animalIcon: null,
    color: null,
  },
  badges: [],
  bannedUntil: null,
  isOnboarded: false,
  isAppOnboarded: false,
  termsAccepted: null,
  referralData: null,
  showContentExplorer: false,
  enteredHabitat: false,
  enteredMap: false,
  // flag to show clip button pulse animation
  clipButtonClicked: false,
  hasWatchedFreemiumTalk: false,
  isRealEmail: false,
  isStreamClicked: false,
  sessionDurationInSec: 0,
  dailyCredits: {
    inUse: false,
    cameraMovement: 0,
    cameraZoom: 0,
    captureClip: 0,
    capturePhoto: 0,
  },
  cohorts: [],
  maximumNumberOfFreeHabitats: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSubscriptionData: (state, { payload }: PayloadAction<Partial<UserState['subscription']>>) => {
      state.subscription = {
        ...state.subscription,
        ...payload,
      };
    },
    // TODO: copy the user response type from mobile app
    setUserData: (state, { payload }: PayloadAction<any>) => {
      const { profile, subscriptionStatus: subscription = {}, termsAcceptance, ...rest } = payload;

      return {
        ...state,
        ...rest,
        sessionChecked: true,
        logged: true,
        profile: profile || initialState.profile,
        // TODO: implement versioning when we need it
        termsAccepted: termsAcceptance.length !== 0,
        subscription: {
          ...state.subscription,
          ...subscription,
        },
      };
    },
    unsetUserData: (state) => ({
      ...initialState,
      role: 'guest',
      sessionChecked: true,
      referralData: state.referralData,
    }),
    setUserProfile: (state, { payload }: PayloadAction<Pick<UserState, 'profile' | 'username'>>) => {
      state.profile = payload.profile;
      state.username = payload.username;
    },
    setSessionChecked: (state) => {
      state.sessionChecked = true;
    },
    setIsRealEmail: (state) => {
      state.isRealEmail = true;
    },
    setTermsAccepted: (state) => {
      state.termsAccepted = true;
    },
    toggleFavoriteHabitat: (state, { payload }: PayloadAction<string>) => {
      state.favoriteHabitats ??= [];

      if (state.favoriteHabitats.includes(payload)) {
        state.favoriteHabitats = state.favoriteHabitats.filter((id) => id !== payload);
      } else {
        state.favoriteHabitats.push(payload);
      }
    },
    setReferralData: (state, { payload }: PayloadAction<UserState['referralData']>) => {
      state.referralData = payload;
    },
    setClipButtonClicked: (state, { payload }: PayloadAction<boolean>) => {
      state.clipButtonClicked = payload;
    },
    updateUserProperty: (state, { payload }: PayloadAction<Partial<UserState>>) => ({ ...state, ...payload }),
    updateABTestVariants: (state, { payload }: PayloadAction<Record<string, string>>) => {
      state.abTestVariants = {
        ...state.abTestVariants,
        ...payload,
      };
    },
    decrementDailyCredit: (state, { payload }: PayloadAction<DailyCreditType>) => {
      if (state.dailyCredits?.inUse && state.dailyCredits[payload] > 0) {
        state.dailyCredits[payload] -= 1;
      }
    },
  },
});

export default userSlice;
