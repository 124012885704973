export const SET_DATE_FILTER = 'SET_DATE_FILTER';
export const TOGGLE_DATE_FILTER = 'TOGGLE_DATE_FILTER';

export const SET_ZOO_FILTER = 'SET_ZOO_FILTER';
export const TOGGLE_ZOO_FILTER = 'TOGGLE_ZOO_FILTER';

export const SET_EVENT_FILTER = 'SET_EVENT_FILTER';
export const TOGGLE_EVENT_FILTER = 'TOGGLE_EVENT_FILTER';

export const SET_ANIMAL_FILTER = 'SET_ANIMAL_FILTER';
export const TOGGLE_ANIMAL_FILTER = 'TOGGLE_ANIMAL_FILTER';

export const SET_TIME_FILTER = 'SET_TIME_FILTER';
export const TOGGLE_TIME_FILTER = 'TOGGLE_TIME_FILTER';

export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS';
