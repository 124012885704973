import Hotjar from '@hotjar/browser';
import mixpanel from 'mixpanel-browser';

import { isDev } from '../helpers';
import store from '../redux/store';

let isMPInitialized = false;
let isHotjarInitialized = false;
const isCookieEnabled = () => {
  try {
    // "navigator.cookieEnabled" is not reliable so we check it by creating/deleting a test cookie
    // create cookie
    document.cookie = 'cookietest=1';
    const ret = document.cookie.indexOf('cookietest=') !== -1;
    // delete cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return ret;
  } catch (e) {
    return false;
  }
};

const initializeHotjar = () => {
  if (!isHotjarInitialized) {
    try {
      const hotjarVersion = 6;
      Hotjar.init(process.env.PREACT_APP_HOTJAR_SITE_ID, hotjarVersion, {
        debug: isDev(),
      });
      isHotjarInitialized = true;
    } catch (err) {
      console.error('error on Hotjar initializing', err);
    }
  }
};

export const initializeMP = () => {
  try {
    const storageMethod = isCookieEnabled() ? 'cookie' : 'localStorage';
    if (!isMPInitialized) {
      const proxyApiURL = process.env.PREACT_APP_API_AUTHORITY.includes('localhost')
        ? 'api.zoolife.live'
        : process.env.PREACT_APP_API_AUTHORITY;
      mixpanel.init(process.env.PREACT_APP_MIXPANEL_TOKEN, {
        debug: isDev(),
        api_host: `https://${proxyApiURL}/mp`,
        ignore_dnt: true,
        persistence: storageMethod,
      });
      isMPInitialized = true;
    }
    initializeHotjar();
  } catch (err) {
    console.error('error on mixpanel initializing ', err);
  }
};

const createLeanUserObject = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => !['gaClientIds', 'sessionDurations'].includes(key)));

const getUrlParams = () => {
  const excludeKeys = ['time', 'distinct_id', 'id', 'Id', 'ID', 'ml', 'pass', 'bundleUserPass'];
  const urlParams = new URLSearchParams(typeof window !== 'undefined' && window.location.search);
  return Object.fromEntries(Array.from(urlParams.entries()).filter(([key]) => !excludeKeys.includes(key)));
};

export const trackMP = (event, options = null) => {
  try {
    const currentEvent = event;
    if (!isMPInitialized) {
      initializeMP();
    }
    if (!isHotjarInitialized) {
      initializeHotjar();
    }

    const { user = {} } = store.getState();
    const paramsObject = getUrlParams();
    const leanerUserObj = createLeanUserObject(user);

    const data = { ...leanerUserObj, ...options, ...paramsObject };

    if (leanerUserObj?.userId) {
      data.distinct_id = user.userId;
    }
    mixpanel.track(currentEvent, data);
    if (
      currentEvent === 'freemium-onboarding' ||
      currentEvent === 'login' ||
      currentEvent === 'stripe-checkout-completed' ||
      currentEvent === 'redeem-giftcard' ||
      currentEvent === 'freehabitats-selection-status'
    ) {
      // if the user signs up OR logs in with a new device, we will let MP know that the random
      // distinct_id which was created automatically should be replaced with the user's mongo Id,
      // so we can trace the old user on any new device .
      if (user.userId) {
        try {
          const isBundleUser = user.subscription?.active && user.subscription?.productname?.includes('bundle');
          if (new Date(user.createdAt).toDateString() === new Date().toDateString()) {
            // if user is created today
            Hotjar.event('first-day-user');
            if (isBundleUser) {
              Hotjar.event('first-day-bundle-user');
            }
          } else if (isBundleUser) {
            Hotjar.event('bundle-user');
          }
        } catch (err) {
          console.error('error on hotjar event tracking', err);
        }
        mixpanel.identify(user.userId);
        // Storing user profile on MP
        mixpanel.people.set(user);
        Hotjar.identify(user.userId, user);
      }
    }
  } catch (err) {
    console.error('error on mixpanel tracking', err);
  }
};

export const updateUserOnMP = (userObj) => {
  try {
    const leanerUserObj = createLeanUserObject(userObj);
    if (leanerUserObj.userId) {
      mixpanel.identify(leanerUserObj.userId);
      mixpanel.people.set(leanerUserObj);
    }
  } catch (err) {
    console.error('error on upading user profile on mixpanel', err);
  }
};
