import { omit, orderBy } from 'lodash-es';

import {
  ADD_MESSAGES,
  BAN_CHAT_USER,
  CLEAR_MESSAGES,
  MARK_MESSAGE_AS_DELETED,
  MARK_MESSAGE_AS_PINNED,
  SET_REPLY_MESSAGE,
  TOGGLE_MESSAGE_REACTION,
} from '../types';

const initialState = {
  channels: {},
  replyMessage: {
    timetoken: null,
    username: null,
    animal: null,
    color: null,
    text: null,
    channel: null,
  },
  banUser: null,
};

const handleReactionChange = (reactions, type, newReaction) => {
  if (!reactions[type] || !reactions[type].length) {
    return { ...reactions, [type]: [newReaction] };
  }

  const isDelete = reactions[type].some((r) => r.actionTimetoken === newReaction.actionTimetoken);

  if (isDelete && reactions[type].length === 1) {
    return omit(reactions, [type]);
  }

  if (isDelete) {
    return {
      ...reactions,
      [type]: reactions[type].filter((r) => r.actionTimetoken !== newReaction.actionTimetoken),
    };
  }

  return {
    ...reactions,
    [type]: [...reactions[type], newReaction],
  };
};

// sorting the messages based on isPinned (pinned messages at the bottom)
// and timestamp (older messages at the top)
const sortMessages = (messages) =>
  orderBy(
    messages,
    [(message) => !!message.isNew, (message) => !!message.pinAction, (message) => new Date(message.timestamp)],
    ['asc', 'asc', 'asc'],
  );

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_MESSAGES: {
      const { channelId, messages } = payload;
      const oldMessages = (state.channels[channelId]?.messages ?? []).reduce((acc, message) => {
        acc[message.timetoken] = message;
        return acc;
      }, {});
      messages.forEach((message) => {
        oldMessages[message.timetoken] = message;
      });

      const sortedMessages = sortMessages(Object.values(oldMessages));
      return {
        ...state,
        channels: {
          ...state.channels,
          [channelId]: {
            messages: sortedMessages,
          },
        },
      };
    }
    case CLEAR_MESSAGES: {
      const { channelId } = payload;
      return {
        ...state,
        channels: {
          ...state.channels,
          [channelId]: undefined,
        },
      };
    }
    case MARK_MESSAGE_AS_DELETED: {
      const { channelId, messageId } = payload;
      return {
        ...state,
        channels: {
          ...state.channels,
          [channelId]: {
            messages: state.channels[channelId]?.messages?.map((message) =>
              message.timetoken === messageId ? { ...message, isDeleted: true } : message,
            ),
          },
        },
      };
    }
    case MARK_MESSAGE_AS_PINNED: {
      const { channelId, messageId, actionTimetoken, value } = payload;
      return {
        ...state,
        channels: {
          ...state.channels,
          [channelId]: {
            messages: sortMessages(
              state.channels[channelId]?.messages?.map((message) =>
                message.timetoken === messageId
                  ? {
                      ...message,
                      pinAction: message.pinAction
                        ? undefined
                        : {
                            value,
                            actionTimetoken,
                          },
                    }
                  : message,
              ) ?? [],
            ),
          },
        },
      };
    }
    case TOGGLE_MESSAGE_REACTION: {
      const { channelId, messageId, reaction: type, reactionId, userId } = payload;

      return {
        ...state,
        channels: {
          ...state.channels,
          [channelId]: {
            messages: state.channels[channelId]?.messages?.map((message) =>
              message.timetoken === messageId
                ? {
                    ...message,
                    reactions: handleReactionChange(message.reactions, type, { uuid: userId, actionTimetoken: reactionId }),
                  }
                : message,
            ),
          },
        },
      };
    }
    case SET_REPLY_MESSAGE: {
      const { timetoken, userId, username, text, animal, color, channel } = payload;

      return {
        ...state,
        replyMessage: {
          timetoken,
          userId,
          username,
          text,
          animal,
          color,
          channel,
        },
      };
    }
    case BAN_CHAT_USER: {
      const { username } = payload;

      return {
        ...state,
        banUser: username,
      };
    }
    default: {
      return state;
    }
  }
};
