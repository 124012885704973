import '@fortawesome/fontawesome-svg-core/styles.css';
import './style/globalStyle.scss';

import { config as faConfig } from '@fortawesome/fontawesome-svg-core';
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import NotFound from 'async!./routes/notFound';
import ContactUsModalLoader from 'Components/async/ContactUsModalLoader';
import InviteModalLoader from 'Components/async/InviteModalLoader';
import GuestOnlyGuard from 'Components/Authorize/GuestOnlyGuard';
import { Box, Grommet } from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import { useEffect, useErrorBoundary } from 'preact/hooks';
import Router from 'preact-router';
import { connect, Provider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { initializeGA, logPageViewGA } from 'Shared/ga';

import { PRIVACY_PDF_URL, TERMS_PDF_URL } from '@/components/TermsAndConditions/constants';
import { setReferralData } from '@/redux/slices/user/actions';
import TermsAndPrivacy from '@/routes/TermsAndPrivacy';

import SurveyPopup from './components/modals/Survey/SurveyPopup';
import DisableJSRoutingProvider from './components/providers/DisableJSRoutingProvider';
import zoolifeTheme from './grommetTheme';
import { logAndGetCampaignData } from './helpers';
import QueryProvider from './query-provider';
import persistor from './redux/persistor';
import store from './redux/store';
import Album from './routes/album';
import Gift from './routes/gift';
import LandingRedirect from './routes/landingNew/LandingRedirect';
import { initializeMP } from './shared/mp';

const homeTitle = "The world's first virtual zoo.";
const theme = deepMerge(grommet, zoolifeTheme);

// we are manually loading FA css and this should prevent duplication
faConfig.autoAddCss = false;

const Content = ({ showContactUs, showInvite }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const updateRefData = async () => {
      const campaignData = await logAndGetCampaignData();
      dispatch(setReferralData(campaignData));
    };
    updateRefData();
  }, [dispatch]);

  const routerChangeHandler = (props) => {
    const { url } = props;
    logPageViewGA(url);
    if (typeof window !== 'undefined' && window?.fbq) {
      window.fbq('track', 'PageView');
    }
  };

  useEffect(() => {
    initializeGA();
    initializeMP();
    if (typeof window !== 'undefined') {
      logPageViewGA(window.location.pathname);
    }
  }, []);

  const routes = (
    <Box fill className="calculated-full-height">
      <Router onRouteChange={routerChangeHandler}>
        <GuestOnlyGuard path="/" redirectRoute="/home" title={homeTitle}>
          <LandingRedirect />
        </GuestOnlyGuard>

        <Gift path="/gift" title="Gift Cards" />

        <Album mediaType="photos" path="/album/photos/:photoId" />
        <Album mediaType="videos" path="/album/videos/:videoId" />

        <TermsAndPrivacy path="/terms-and-conditions" title="Terms and Conditions" pdfLink={TERMS_PDF_URL} />
        <TermsAndPrivacy path="/privacy-policy" title="Privacy Policy" pdfLink={PRIVACY_PDF_URL} />

        {/* NOTE: NotFound need to be at the end */}
        <NotFound path=":*" />
      </Router>

      <ContactUsModalLoader isOpen={showContactUs} />
      <SurveyPopup />
      <InviteModalLoader isOpen={showInvite} />
    </Box>
  );

  return (
    <Grommet full theme={theme} style={{ background: 'transparent' }}>
      {routes}
    </Grommet>
  );
};

const ConnectedContent = connect(
  ({
    modals: {
      contactus: { isOpen: showContactUs },
      invite: { isOpen: showInvite },
    },
  }) => ({ showContactUs, showInvite }),
)(Content);

const PublicPages = () => {
  useErrorBoundary((err) => {
    console.error(err);
    // TODO: log this somewhere (sentry, logRocket, or GA)
  });

  return (
    <QueryProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <DisableJSRoutingProvider>
            <ConnectedContent />
          </DisableJSRoutingProvider>
        </PersistGate>
      </Provider>
    </QueryProvider>
  );
};

export default PublicPages;
