import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

// `cn` is a short form of ClassName, which is widely used in others tailwind projects
// See: https://ui.shadcn.com/docs/installation/manual#add-a-cn-helper
export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const isDev = () => process.env.PREACT_APP_ENVIRONMENT === 'development';

export const isZoolifeDomain = () =>
  typeof window === 'undefined' || !!window.location.hostname.match(/zoolife\.(tv|live)$/);
