import { cloneElement, toChildArray } from 'preact';
import { useEffect } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { buildURL } from 'Shared/fetch';

import { identifyUser } from '@/helpers';
import checkLocalStorageForODTP from '@/helpers/checkLocalStorageForODTP';
import { setUserData, unsetUserData } from '@/redux/slices/user/actions';
import store from '@/redux/store';

export const useUserEffect = () => {
  const dispatch = useDispatch();
  const { searchParams } = new URL(document.location);

  useEffect(() => {
    const loadUserData = async () => {
      const { user } = store.getState();
      if (!user?.sessionChecked) {
        const odtpEntry = Array.from(searchParams).find(([key]) => key.toLowerCase().includes('odtp'));
        let odtpParam = '';
        if (odtpEntry) {
          const [key, value] = odtpEntry;
          odtpParam = `${key}=${value}`;
          odtpParam = checkLocalStorageForODTP(odtpParam);
        }
        const response = await fetch(buildURL(`/users/user${odtpParam ? `?${odtpParam}` : ''}`), {
          credentials: 'include',
          cachePolicy: 'no-cache',
        });
        if (response.status === 200) {
          const user = await response.json();
          identifyUser(user);
          dispatch(setUserData(user));
        } else {
          dispatch(unsetUserData());
        }
      }
    };

    loadUserData();
  }, [dispatch]);
};

export const mapChildProps = (children, props) => toChildArray(children).map((child) => child && cloneElement(child, props));
