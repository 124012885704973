import { CLOSE_SURVEY_MODAL, OPEN_SURVEY_MODAL } from './types';

type Action = {
  type: string;
  payload: {
    isAutomated: true;
  };
};

const initialState = {
  isOpen: false,
  isAutomated: false,
};

export default (state = initialState, { type, payload }: Action) => {
  if (type === OPEN_SURVEY_MODAL) {
    return {
      ...state,
      isOpen: true,
      isAutomated: payload?.isAutomated,
    };
  }

  if (type === CLOSE_SURVEY_MODAL) {
    return {
      ...state,
      isOpen: false,
      isAutomated: false,
    };
  }

  return state;
};
