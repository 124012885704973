import { MODE_CONTROL_CAMERA_CONTROL, MODE_EMOJI_STICKER } from '../../components/LiveStream/constants';
import {
  ADD_USER_INTERACTION,
  REMOVE_USER_INTERACTION,
  SET_CLIP_DATA,
  SET_FULL_SCREEN,
  SET_SHOW_VIV_TRANSCRIPT,
  SET_SNAPSHOT_DATA,
  SET_VIV_SUPPORTS_TRANSCRIPT,
  SHOW_SNAPSHOT_SHARE_POPUP,
  TOGGLE_EMOJI_STICKER,
  TOGGLE_IS_BROADCASTING,
  TOGGLE_IS_STREAM_PLAYING,
  TOGGLE_SHOW_EMOJI_BASKET,
  TOGGLE_STREAM_AREA_WARNING_MSG,
  UPDATE_VIEWERS,
} from '../types';

const mockEmojis = [
  {
    items: [
      'https://zoolife.tv/assets/emotes/baskets/b1/hat1.webp',
      'https://zoolife.tv/assets/emotes/baskets/b1/hat2.webp',
      'https://zoolife.tv/assets/emotes/baskets/b1/hat3.webp',
      'https://zoolife.tv/assets/emotes/baskets/b1/hat4.webp',
      'https://zoolife.tv/assets/emotes/baskets/b1/hat5.webp',
      'https://zoolife.tv/assets/emotes/baskets/b1/btie1.webp',
      'https://zoolife.tv/assets/emotes/baskets/b1/crown1.webp',
      'https://zoolife.tv/assets/emotes/baskets/b1/crown2.webp',
    ],
    _id: '5fca78766fcc2754d59d9f95',
    icon: 'https://zoolife.tv/assets/emotes/baskets/tab1.png',
  },
  {
    items: [
      'https://zoolife.tv/assets/emotes/baskets/b2/image 235.png',
      'https://zoolife.tv/assets/emotes/baskets/b2/image 239.png',
      'https://zoolife.tv/assets/emotes/baskets/b2/image 237.png',
      'https://zoolife.tv/assets/emotes/baskets/b2/top-hat_1f3a9 1.png',
      'https://zoolife.tv/assets/emotes/baskets/b2/image 240.png',
      'https://zoolife.tv/assets/emotes/baskets/b2/top-hat_1f3a9 2.png',
      'https://zoolife.tv/assets/emotes/baskets/b2/image 60.png',
      'https://zoolife.tv/assets//image58-1605044847985-1607555168474.png',
    ],
    _id: '5fca78766fcc2754d59d9f96',
    icon: 'https://zoolife.tv/assets/emotes/baskets/tab2.png',
  },
  {
    items: [
      'https://zoolife.tv/assets/emotes/baskets/b3/lol.png',
      'https://zoolife.tv/assets/emotes/baskets/b3/cute.png',
      'https://zoolife.tv/assets/emotes/baskets/b3/omg.png',
      'https://zoolife.tv/assets/emotes/baskets/b3/wow.png',
      'https://zoolife.tv/assets/emotes/baskets/b3/fun.png',
      'https://zoolife.tv/assets/emotes/baskets/b3/cool.png',
      'https://zoolife.tv/assets/emotes/baskets/b3/pog.png',
      'https://zoolife.tv/assets/emotes/baskets/b3/xoxo.png',
    ],
    _id: '5fca78766fcc2754d59d9f97',
    icon: 'https://zoolife.tv/assets/emotes/baskets/tab3.png',
  },
];

const initialState = {
  userInteractions: [],
  emojis: mockEmojis,
  interactionState: {
    showEmojiBasket: false,
    showSnapshotShare: true,
    isStreamPlaying: false,
    isBroadcasting: false,
    mode: MODE_CONTROL_CAMERA_CONTROL,
    emojiSrc: null,
    showHostOnlyWarningMsg: false,
  },
  viewers: 0,
  fullScreen: false,
  snapshot: {},
  clip: {},
  showVivTranscript: true,
  vivSupportsTranscript: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_USER_INTERACTION: {
      return {
        ...state,
        userInteractions: [...state.userInteractions, payload],
      };
    }
    case UPDATE_VIEWERS: {
      const { viewers } = payload;
      return {
        ...state,
        viewers,
      };
    }

    case REMOVE_USER_INTERACTION: {
      const { interactionId: id } = payload;

      return {
        ...state,
        userInteractions: state.userInteractions.filter((item) => item.interactionId !== id),
      };
    }

    case TOGGLE_SHOW_EMOJI_BASKET: {
      return {
        ...state,
        interactionState: {
          ...state.interactionState,
          showEmojiBasket: !state.interactionState.showEmojiBasket,
        },
      };
    }

    case TOGGLE_EMOJI_STICKER: {
      const { isOn, emojiSrc } = payload;

      return {
        ...state,
        interactionState: {
          ...state.interactionState,
          mode: isOn ? MODE_EMOJI_STICKER : MODE_CONTROL_CAMERA_CONTROL,
          emojiSrc,
        },
      };
    }

    case TOGGLE_STREAM_AREA_WARNING_MSG: {
      return {
        ...state,
        interactionState: {
          ...state.interactionState,
          showStreamWarningMsg: payload,
        },
      };
    }

    case TOGGLE_IS_STREAM_PLAYING: {
      return {
        ...state,
        interactionState: {
          ...state.interactionState,
          isStreamPlaying: !state.interactionState.isStreamPlaying,
        },
      };
    }
    case TOGGLE_IS_BROADCASTING: {
      return {
        ...state,
        interactionState: {
          ...state.interactionState,
          isBroadcasting: !state.interactionState.isBroadcasting,
        },
      };
    }
    case SHOW_SNAPSHOT_SHARE_POPUP: {
      const { show } = payload;
      return {
        ...state,
        interactionState: {
          ...state.interactionState,
          showSnapshotShare: show,
        },
      };
    }

    case SET_FULL_SCREEN: {
      const { isActive } = payload;
      return { ...state, fullScreen: isActive };
    }

    case SET_SNAPSHOT_DATA: {
      const { data } = payload;
      return { ...state, snapshot: data };
    }

    case SET_CLIP_DATA: {
      const { data } = payload;
      return { ...state, clip: data };
    }

    case SET_VIV_SUPPORTS_TRANSCRIPT: {
      return {
        ...state,
        vivSupportsTranscript: payload,
      };
    }

    case SET_SHOW_VIV_TRANSCRIPT: {
      return {
        ...state,
        showVivTranscript: payload,
      };
    }

    default: {
      return state;
    }
  }
};
