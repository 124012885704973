export const SET_HABITAT_CARDS = 'SET_HABITAT_CARDS';
export const ADD_HABITAT_CARD = 'ADD_HABITAT_CARD';
export const UPDATE_HABITAT_CARD = 'UPDATE_HABITAT_CARD';

export const DELETE_HABITAT_CARD = 'DELETE_HABITAT_CARD';
export const SET_CARDS_LOADING = 'SET_CARDS_LOADING';

export const SET_HABITAT_TAB = 'SET_HABITAT_TAB';
export const SET_CARDS_SHORTCUT = 'SET_CARDS_SHORTCUT';

export const UPDATE_QUIZ_RESULT = 'UPDATE_QUIZ_RESULT';
