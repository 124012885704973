import { ComponentChildren, FunctionComponent } from 'preact';
import { Can, hasPermission as sharedHasPermission, isFreeUser as sharedIsFreeUser, Role } from 'zl-shared';

import store from '../../redux/store';
import rules from './rules';

export const hasPermission = (action: string, data?: any) => {
  const {
    user: { role },
  } = store.getState();

  return sharedHasPermission(action, data, role as Role, rules);
};

export const isFreeUser = () => {
  const {
    user: { role, subscription },
  } = store.getState();
  return sharedIsFreeUser(role as Role, subscription as { active?: boolean });
};

export default Can(hasPermission) as FunctionComponent<{
  perform: string;
  yes?: () => ComponentChildren;
  no?: () => ComponentChildren;
  data?: any;
}>;
