import { QueryClient, QueryClientConfig, QueryClientProvider, QueryFunction } from '@tanstack/react-query';
import { ReactNode } from 'preact/compat';

import { queryKeyHashFnForPubnub } from '@/queries/utils';
import { buildURL } from '@/shared/fetch';

interface QueryProviderProps {
  queryClient?: QueryClient;
  children: ReactNode | ReactNode[];
}

/**
 * TODO: make it more compatible with RESTful, cache-able queries, like build the url from the multiple queryKey elements
 * e.g. ['users', 'detail', id] => `/users/${id}`
 */
export const defaultQueryFn: QueryFunction = async (ctx) => {
  const [path] = ctx.queryKey;
  const res = await fetch(buildURL(path as string), {
    signal: ctx.signal,
  });
  return res.json();
};

export const createQueryClient = (options?: QueryClientConfig) =>
  new QueryClient({
    ...options,
    defaultOptions: {
      ...options?.defaultOptions,
      queries: {
        queryFn: defaultQueryFn,
        queryKeyHashFn: queryKeyHashFnForPubnub,
        ...options?.defaultOptions?.queries,
      },
    },
  });

const defaultQueryClient = createQueryClient();

const QueryProvider = ({ children, queryClient }: QueryProviderProps) => {
  return <QueryClientProvider client={queryClient ?? defaultQueryClient}>{children}</QueryClientProvider>;
};

export default QueryProvider;
