import { combineReducers } from 'redux';

import appOnboardingModal from '@/components/AppOnboarding/redux/slice';
import membersOnlyWarningModal from '@/components/MembersOnlyWarningModal/redux/slice';
import contactus from '@/components/modals/ContactUs/reducer';
import installApp from '@/components/modals/InstallApp/reducer';
import survey from '@/components/modals/Survey/reducer';
import invite from '@/components/NavBar/Invite/reducer';
import pushNotification from '@/components/NotificationPermissionModal/reducer';
import terms from '@/components/TermsAndConditions/redux/slice';

export default combineReducers({
  [terms.name]: terms.reducer,
  invite,
  contactus,
  installApp,
  pushNotification,
  survey,
  [membersOnlyWarningModal.name]: membersOnlyWarningModal.reducer,
  [appOnboardingModal.name]: appOnboardingModal.reducer,
});
