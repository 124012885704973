// tabs
export const MEET = 'meet';
export const INFO = 'info';
export const SCHEDULES = 'SCHEDULES';
export const ALBUM = 'album';
export const EXPERT_TALKS = 'expert talks';
export const QUESTIONS = 'QUESTIONS';
export const SPONSOR = 'sponsor';

// tags
export const QUICK_LOOK = 'Quick Look';
export const FOOD_AND_DIET = 'Food & Diet';
export const ORIGIN_AND_HABITAT = 'Origin & Habitat';
export const THE_ANIMAL_BODY = 'The Animal Body';
export const CONSERVATION = 'Conservation';
export const BEHAVIOR = 'Behavior';
export const FAMILY_LIFE = 'Family Life';
export const SOUND = 'Sound';
export const SPONSOR_TAG = 'Support';
export const EMPTY_TAG = '';

// card tabs
export const SINGLE_ICON_CARD_TYPE = 'SINGLE_ICON_CARD_TYPE';
export const THREE_ICONS_CARD_TYPE = 'THREE_ICONS_CARD_TYPE';
export const FOUR_ICONS_CARD_TYPE = 'FOUR_ICONS_CARD_TYPE';
export const ANIMAL_PROFILE_CARD_TYPE = 'ANIMAL_PROFILE_CARD_TYPE';
export const CONSERVATION_CARD_TYPE = 'CONSERVATION_CARD_TYPE';
export const TWO_VIDEOS_CARD_TYPE = 'TWO_VIDEOS_CARD_TYPE';
export const SINGLE_VIDEO_CARD_TYPE = 'SINGLE_VIDEO_CARD_TYPE';
export const ORIGIN_AND_HABITAT_CARD_TYPE = 'ORIGIN_AND_HABITAT_CARD_TYPE';
export const ANIMAL_BODY_CARD_TYPE = 'ANIMAL_BODY_CARD_TYPE';
export const QUIZ_CARD_TYPE = 'QUIZ_CARD_TYPE';
export const SPONSOR_CARD_TYPE = 'SPONSOR_CARD_TYPE';

// conservation status
export const CRITICALLY_ENDANGERED = 'critically-endangered';
export const DATA_DEFICIENT = 'data-deficient';
export const ENDANGERED = 'endangered';
export const EXTINCT_IN_THE_WILD = 'extinct-in-the-wild';
export const EXTINCT = 'extinct';
export const LEAST_CONCERNED = 'least-concerned';
export const NEAR_THREATENED = 'near-threatened';
export const VULNERABLE = 'vulnerable';
