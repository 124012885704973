import { CLOSE_TRIM_VIDEO_MODAL, SHOW_TRIM_VIDEO_MODAL } from './types';

const initialState = { isOpen: false };

export default (state = initialState, { type }) => {
  if (type === SHOW_TRIM_VIDEO_MODAL) {
    return { ...state, isOpen: true };
  }

  if (type === CLOSE_TRIM_VIDEO_MODAL) {
    return { ...state, isOpen: false };
  }

  return state;
};
