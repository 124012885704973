import AppLoader from 'Components/AppLoader';
import { useUserEffect } from 'Components/Authorize/guardHelpers';
import { connect } from 'react-redux';

import { getRedirectURLLocalStorage, loadPage } from '../../../helpers';

const GuestOnlyGuard = ({ redirectRoute, user, fallback, children, path, url }) => {
  useUserEffect();
  if (user?.logged) {
    if (fallback) {
      return fallback;
    }
    const newPath = getRedirectURLLocalStorage();
    const { search } = window.location;
    const redirectRouteWithSearchParams =
      ['/map', '/home'].includes(redirectRoute) && search ? `${redirectRoute}${search}` : redirectRoute;
    // here we use loadPage(window.open or window.location) instead of route
    // is due that the main routes don't exist in the public routing pages
    loadPage(url.replace(path, newPath ?? redirectRouteWithSearchParams));
  }

  if (user?.sessionChecked) {
    return children;
  }

  return <AppLoader />;
};

export default connect(({ user }) => ({ user }))(GuestOnlyGuard);
