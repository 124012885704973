import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type MyAlbumState from '@/redux/slices/myAlbum/types';

interface ShareModalState {
  open: boolean;
  mediaId?: string;
  prevId?: string;
  nextId?: string;
  data?: MyAlbumState['album']['list'][0];
}

const initialState: ShareModalState = Object.freeze({
  open: false,
});

const shareModalSlice = createSlice({
  name: 'shareModal',
  initialState,
  reducers: {
    setShareModalData: (state, { payload }: PayloadAction<Omit<ShareModalState, 'open'>>) => {
      const { mediaId, nextId, prevId, data } = payload;

      return {
        open: !!mediaId?.length,
        mediaId,
        prevId,
        nextId,
        data,
      };
    },
    closeShareModal: () => initialState,
  },
});

export default shareModalSlice;
