/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PlansState } from './types';

const initialState: PlansState = {
  plans: [],
};

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlans: (state, action: PayloadAction<PlansState['plans']>) => {
      state.plans = action.payload;
    },
  },
});

export default plansSlice;
