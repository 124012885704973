import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import notifications from '../../components/Notifications/reducer';
import scheduleEvents from '../../components/ScheduleEvents/reducer';
import habitat from '../../routes/habitat/reducer';
import magicLink from '../../routes/login/MagicLinkModal/reducer';
import passwordReset from '../../routes/login/ResetModal/reducer';
import map from '../../routes/map/reducer';
import schedule from '../../routes/schedule/reducer';
import deviceSettingsSlice from '../slices/deviceSettings';
import myAlbumSlice from '../slices/myAlbum';
import plansSlice from '../slices/plans';
import remoteConfigSlice from '../slices/remoteConfig';
import settingsSlice from '../slices/settings';
import userSlice from '../slices/user';
import allHabitats from './allHabitats';
import chat from './chat';
import config from './config';
import geoInfo from './geoInfo';
import mainStream from './mainStream';
import modals from './modals';

const settingsPersistConfig = {
  version: 1,
  key: settingsSlice.name,
  storage,
};

const remoteConfigPersistConfig = {
  version: 1,
  key: remoteConfigSlice.name,
  storage,
};

const deviceSettingsPersistConfig = {
  version: 1,
  key: deviceSettingsSlice.name,
  storage,
};

export default combineReducers({
  allHabitats,
  mainStream,
  [userSlice.name]: userSlice.reducer,
  chat,
  habitat,
  [plansSlice.name]: plansSlice.reducer,
  map,
  schedule,
  [myAlbumSlice.name]: myAlbumSlice.reducer,
  passwordReset,
  magicLink,
  modals,
  notifications,
  scheduleEvents,
  config,
  geoInfo,
  [remoteConfigSlice.name]: persistReducer(remoteConfigPersistConfig, remoteConfigSlice.reducer),
  [settingsSlice.name]: persistReducer(settingsPersistConfig, settingsSlice.reducer),
  [deviceSettingsSlice.name]: persistReducer(deviceSettingsPersistConfig, deviceSettingsSlice.reducer),
});
