import { setLocalStorageWithODTP } from '@/helpers';
import { setGAUserId } from '@/shared/ga';

import userSlice from './index';

export const setUserData = ({ _id: userId, ...rest }: Parameters<typeof userSlice.actions.setUserData>[0]) => {
  setGAUserId(userId);
  if (rest.subscriptionStatus?.active) {
    setLocalStorageWithODTP(rest.subscriptionStatus);
  }
  return userSlice.actions.setUserData({ userId, ...rest });
};

export const {
  setSubscriptionData,
  // setUserData, // It is replaced by the wrapped version
  unsetUserData,
  setUserProfile,
  setSessionChecked,
  setIsRealEmail,
  setTermsAccepted,
  toggleFavoriteHabitat,
  setReferralData,
  setClipButtonClicked,
  updateUserProperty,
  updateABTestVariants,
  decrementDailyCredit,
} = userSlice.actions;
