export const SET_USER_DATA = 'SET_USER_DATA';
export const UNSET_USER_DATA = 'UNSET_USER_DATA';
export const UPDATE_USER_PROPERTY = 'UPDATE_USER_PROPERTY';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const UPDATE_SUBSCRIPTION_DATA = 'UPDATE_SUBSCRIPTION_DATA';
export const SET_SESSION_CHECHED = 'SET_SESSION_CHECHED';
export const SET_IS_REAL_EMAIL = 'SET_IS_REAL_EMAIL';
export const SET_SUBSCRIPTION_DATA = 'SET_SUBSCRIPTION_DATA';
export const UPDATE_FAVORITE_HABITATS = 'UPDATE_FAVORITE_HABITATS';

export const ADD_USER_INTERACTION = 'ADD_USER_INTERACTION';
export const REMOVE_USER_INTERACTION = 'REMOVE_USER_INTERACTION';

export const TOGGLE_IS_STREAM_PLAYING = 'TOGGLE_IS_STREAM_PLAYING';
export const TOGGLE_SHOW_EMOJI_BASKET = 'TOGGLE_SHOW_EMOJI_BASKET';
export const TOGGLE_EMOJI_STICKER = 'TOGGLE_EMOJI_STICKER';

export const ADD_MESSAGES = 'ADD_MESSAGES';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const MARK_MESSAGE_AS_DELETED = 'MARK_MESSAGE_AS_DELETED';
export const MARK_MESSAGE_AS_PINNED = 'MARK_MESSAGE_AS_PINNED';
export const TOGGLE_MESSAGE_REACTION = 'TOGGLE_MESSAGE_REACTION';

export const SHOW_SNAPSHOT_SHARE_POPUP = 'SHOW_SNAPSHOT_SHARE_POPUP';

export const TOGGLE_IS_BROADCASTING = 'TOGGLE_IS_BROADCASTING';

export const UPDATE_REFERRAL_DATA = 'UPDATE_REFERRAL_DATA';

export const SET_HABITATS = 'SET_HABITATS';
export const UNSET_HABITATS = 'UNSET_HABITATS';
export const UPDATE_HABITAT = 'UPDATE_HABITAT';

export const UPDATE_CLIP_BUTTON_CLICKED = 'UPDATE_CLIP_BUTTON_CLICKED';

export const UPDATE_VIEWERS = 'UPDATE_VIEWERS';

export const SET_REPLY_MESSAGE = 'SET_REPLY_MESSAGE';
export const BAN_CHAT_USER = 'BAN_CHAT_USER';
export const TOGGLE_STREAM_AREA_WARNING_MSG = 'TOGGLE_STREAM_AREA_WARNING_MSG';

export const SET_FULL_SCREEN = 'SET_FULL_SCREEN';

export const SET_SNAPSHOT_DATA = 'SET_SNAPSHOT_DATA';
export const SET_CLIP_DATA = 'SET_CLIP_DATA';

export const SET_IS_MULTI_FREE_HABITAT = 'SET_IS_MULTI_FREE_HABITAT';

export const SET_SHOW_VIV_TRANSCRIPT = 'SET_SHOW_VIV_TRANSCRIPT';
export const SET_VIV_SUPPORTS_TRANSCRIPT = 'SET_VIV_SUPPORTS_TRANSCRIPT';

export const SET_GEO_INFO = 'SET_GEO_INFO';
