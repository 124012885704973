import { dynamicRules, rules } from 'zl-shared';

import store from '../../redux/store';

const canEditZoo = () => {
  const {
    user: { zooId: userZooId },
    habitat,
  } = store.getState();

  return dynamicRules.canEditZoo(userZooId, habitat?.habitatInfo?.zoo?._id);
};

const canEditSchedules = (data = {}) => {
  const {
    user: { habitats: allowedHabitats },
    habitat: {
      habitatInfo: { _id: currentHabitatId },
    },
  } = store.getState();

  const { habitatId } = data;

  return dynamicRules.canEditSchedules(allowedHabitats, habitatId, currentHabitatId);
};

const canEditHabitat = () => {
  const {
    user: { habitats },
    habitat: {
      habitatInfo: { _id: habitatId },
    },
  } = store.getState();

  return dynamicRules.canEditHabitat(habitats, habitatId);
};

const canSeeRedeem = () => {
  const {
    user: { logged },
  } = store.getState();

  return dynamicRules.canSeeRedeem(logged);
};

const canSeeAllHabitats = () => {
  const {
    user: {
      role,
      subscription: { active },
    },
  } = store.getState();

  return dynamicRules.canSeeAllHabitats(role, active);
};

export default rules({
  canEditZoo,
  canEditSchedules,
  canEditHabitat,
  canSeeRedeem,
  canSeeAllHabitats,
});
