import { SET_IS_MULTI_FREE_HABITAT } from '../types';

const initialState = {
  isMultiFreeHabitat: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_MULTI_FREE_HABITAT: {
      return {
        ...state,
        isMultiFreeHabitat: payload.data,
      };
    }
    default: {
      return state;
    }
  }
};
