import { dayMs, minuteMs } from '../../../helpers';

export const SURVEY_TYPE_FORM_ID = 'SYJtH44D';
export const SURVEYS_LOCAL_STORAGE_KEY = 'surveys_local_storage';
// Time delay for showing survey on habitat page
export const SURVEYS_HABITAT_DELAY_MS = 120 * minuteMs;
// Min time delay for showing survey on habitat page
export const SURVEYS_HABITAT_MIN_DELAY_MS = 2000;
// Time delay for showing survey again
export const SURVEYS_SEEN_DELAY_MS = 30 * dayMs;
