import { Widget } from '@typeform/embed-react';
import { FunctionComponent } from 'preact';
import { connect, Matching } from 'react-redux';

import { getDeviceType } from '../../../../helpers';
import { ReduxModel } from '../../../../redux/models';
import { trackMP } from '../../../../shared/mp';
import CloseButton from '../../CloseButton';
import { closeSurveyModal } from '../actions';
import { SURVEY_TYPE_FORM_ID, SURVEYS_LOCAL_STORAGE_KEY } from '../constants';
import { getSurveyStorage } from '../helpers';
import style from './style.scss';

type Props = {
  userId: string;
  email: string | undefined;
  role: string | undefined;
  isOpen: boolean;
  isAutomated: boolean;
  closeSurveyModalAction: () => void;
};

const SurveyPopup: FunctionComponent<Props> = ({ isAutomated, userId, email, role, isOpen, closeSurveyModalAction }) => {
  const onClose = (e: any | null) => {
    e?.stopPropagation();
    closeSurveyModalAction();
  };

  const onReady = () => {
    if (isAutomated) {
      const surveys = getSurveyStorage();
      surveys.seen.unshift({
        id: SURVEY_TYPE_FORM_ID,
        date: Date.now(),
      });
      localStorage.setItem(SURVEYS_LOCAL_STORAGE_KEY, JSON.stringify(surveys));
    }
  };

  const onSubmit = () => {
    const surveys = getSurveyStorage();
    surveys.submitted.push({
      id: SURVEY_TYPE_FORM_ID,
    });
    trackMP('feedback-submitted');
    localStorage.setItem(SURVEYS_LOCAL_STORAGE_KEY, JSON.stringify(surveys));
    onClose(null);
  };

  if (!isOpen || role === 'host') {
    return <></>;
  }

  return (
    <div className={style.body} onClick={onClose}>
      <div className={style.content}>
        <div className={style.header}>
          Feedback
          <CloseButton onClick={onClose} className={style.close} />
        </div>
        <Widget
          id={SURVEY_TYPE_FORM_ID}
          className={style.widget}
          hidden={{
            userId,
            email: email || '',
            device: getDeviceType(),
          }}
          onReady={onReady}
          onSubmit={onSubmit}
          keepSession={false}
          autoResize
          inlineOnMobile
          lazy
        />
      </div>
    </div>
  );
};

export default connect(
  ({
    user: { userId, email, role },
    modals: {
      survey: { isOpen, isAutomated },
    },
  }: ReduxModel) => ({
    userId,
    email,
    role,
    isOpen,
    isAutomated,
  }),
  {
    closeSurveyModalAction: closeSurveyModal,
  },
)(SurveyPopup as FunctionComponent<Matching<Props, any>>);
