/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AppOnboardingModalStep = 'welcomeMessage' | 'avatarMaker' | 'habitatsSelector';
export type AppOnboardingModalState = Record<AppOnboardingModalStep, boolean>;

const initialState: AppOnboardingModalState = {
  welcomeMessage: false,
  avatarMaker: false,
  habitatsSelector: false,
};

const appOnboardingModalSlice = createSlice({
  name: 'appOnboardingModal',
  initialState,
  reducers: {
    flagAppOnboardingModalStep: (state, { payload }: PayloadAction<AppOnboardingModalStep>) => {
      state[payload] = true;
    },
  },
});

export default appOnboardingModalSlice;
