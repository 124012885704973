import { isArray } from 'lodash-es';
import { route } from 'preact-router';

import { getDeviceType } from '../../helpers';

export const goToHabitatPage = (zooSlug, habitatSlug) => {
  route(`/h/${zooSlug}/${habitatSlug}`);
};

export const getUrlParam = (param) => {
  let queryString = '';

  if (typeof window !== 'undefined') {
    queryString = window.location.search;
  }

  const urlParams = new URLSearchParams(queryString);

  if (isArray(param)) {
    return param.map((i) => urlParams.get(i));
  }

  return urlParams.get(param);
};

export const replaceUrlParam = (param, value) => {
  const params = new URLSearchParams(window.location.search);
  params.set(param, value);
  return `${window.location.pathname}?${params.toString()}`;
};

export const removeUrlParam = (param) => {
  const urlObject = new URL(window.location.href);
  const params = new URLSearchParams(urlObject.search);
  params.delete(param);
  urlObject.search = params.toString();
  return urlObject.toString().replace(window.location.origin, '');
};

export const getLiveTalkSize = (width, minSize = 150) => {
  const device = getDeviceType();

  if (device === 'phone') {
    return Math.max(width * 0.35, minSize);
  }

  if (device === 'tablet') {
    return Math.max(width * 0.3, minSize);
  }

  return Math.max(width * 0.25, minSize);
};
