import { SHOW_PUSH_NOTIFICATION_MODAL } from './types';

const initialState = { isOpen: false };

export default (state = initialState, { type, payload }) => {
  if (type === SHOW_PUSH_NOTIFICATION_MODAL) {
    const { isOpen } = payload;
    return { ...state, isOpen };
  }

  return state;
};
