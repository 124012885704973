/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import EXPERIMENTS from '@/components/ab-test/experiments';

import { DeviceSettingsState } from './types';

const initialState: DeviceSettingsState = {
  abTestVariants: {},
};

const deviceSettingsSlice = createSlice({
  name: 'deviceSettings',
  initialState,
  reducers: {
    setDeviceVariant: (state, { payload }: PayloadAction<{ experiment: string; variant: string }>) => {
      state.abTestVariants[payload.experiment] = payload.variant;
    },
    unsetEndedExperiments: (state) => {
      const existingExperiments = new Set<string>(Object.values(EXPERIMENTS).map((experiment) => experiment.name));
      Object.keys(state.abTestVariants).forEach((experiment) => {
        if (!existingExperiments.has(experiment)) {
          delete state.abTestVariants[experiment];
        }
      });
    },
  },
});

export default deviceSettingsSlice;
