import { createContext } from 'preact';
import { ReactNode } from 'preact/compat';
import { useContext } from 'preact/hooks';

const Context = createContext<true | undefined>(undefined);

export const useShouldDisableJSRouting = () => useContext(Context);

const DisableJSRoutingProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value>{children}</Context.Provider>
);

export default DisableJSRoutingProvider;
