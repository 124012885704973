import { SET_GEO_INFO } from '../types';

const initialState = {
  isUsOrCa: false,
  currency: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GEO_INFO: {
      return {
        ...state,
        isUsOrCa: payload.isUsOrCa,
        currency: payload.currency,
      };
    }
    default: {
      return state;
    }
  }
};
