/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TermsState } from './types';

const initialState: TermsState = { isOpen: false, file: 'terms', isCloseDisabled: false };

const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    openTermsModal: (state, { payload }: PayloadAction<Partial<Omit<TermsState, 'isOpen'>> | undefined>) => {
      state.isCloseDisabled = payload?.isCloseDisabled ?? initialState.isCloseDisabled;
      state.file = payload?.file ?? initialState.file;
      state.isOpen = true;
    },
    closeTermsModal: (state) => {
      state.isOpen = false;
    },
  },
});

export default termsSlice;
