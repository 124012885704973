import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import middlewares from './middlewares';
import rootReducer from './reducer';

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.PREACT_APP_ENVIRONMENT === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middlewares),
});

if (module.hot) {
  module.hot.accept('./reducer', () => {
    // eslint-disable-next-line global-require
    const { default: nextRootReducer } = require('./reducer');
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
