import { SURVEYS_LOCAL_STORAGE_KEY } from './constants';
import { SurveyStorageModel } from './models';

// eslint-disable-next-line import/prefer-default-export
export const getSurveyStorage = (): SurveyStorageModel => {
  const survey = JSON.parse(localStorage.getItem(SURVEYS_LOCAL_STORAGE_KEY) ?? '{}') as SurveyStorageModel;
  if (!survey.seen) {
    survey.seen = [];
  }
  if (!survey.submitted) {
    survey.submitted = [];
  }
  return survey;
};
