import ReactGA from 'react-ga4';

import { isDev } from '@/lib/utils';

let isGAInitialized = false;

export const initializeGA = () => {
  if (!isGAInitialized) {
    ReactGA.initialize(process.env.PREACT_APP_GA_APPID, { debug: isDev() });
    isGAInitialized = true;
  }
};

export const getClientId = async () => {
  if (!isGAInitialized) {
    initializeGA();
  }
  return new Promise((resolve, reject) => {
    ReactGA.ga((tracker) => {
      const clientId = tracker?.get('clientId');
      if (clientId) {
        resolve(clientId);
      } else {
        reject(Error('Cannot get client id of GA'));
      }
    });
  });
};

export const logPageViewGA = (page, resetReferrer, delay = true) => {
  if (!isGAInitialized) {
    initializeGA();
  }

  if (resetReferrer) {
    ReactGA.set({ referrer: '' });
  }

  if (delay) {
    setTimeout(() => {
      ReactGA.send({ hitType: 'pageview', page, title: page });
    }, 2000);
  } else {
    ReactGA.send({ hitType: 'pageview', page, title: page });
  }
};

export const logGAEvent = async (category, action, label, value = 1) => {
  // category ->  content type: chat/ugc
  // actions -> action the user took: sent-message-chat, liked-photo, reacted-comment-clip
  // label -> the habitat slug
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};

export const logLandingButton = async (action) => {
  const { pathname } = window.location;
  const modifiedPath = ['/', '/home'].includes(pathname) ? '/zoolife' : pathname;
  await logGAEvent('PressLandingButton', `${modifiedPath}-${action}`);
};

export const setGAUserId = (userId) => {
  if (!isGAInitialized) {
    initializeGA();
  }

  ReactGA.set({ userId, anonymizeIp: true, forceSSL: true });
};

export const logExperiment = (experimentId, variant) => {
  ReactGA.ga('set', 'exp', `${experimentId}.${variant}`);
};
