/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { unsetUserData } from '../user/actions';
import { SettingsState } from './types';

const initialState: SettingsState = {
  overview: {
    collapsed: false,
    latestUpdatedAt: 0,
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setOverviewCollapsed: (state, { payload }: PayloadAction<boolean>) => {
      state.overview.collapsed = payload;
    },
    setOverviewLatestUpdatedAt: (state, { payload }: PayloadAction<number>) => {
      state.overview.latestUpdatedAt = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(unsetUserData, () => initialState);
  },
});

export default settingsSlice;
