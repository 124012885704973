import { useEffect } from 'preact/hooks';

import AppLoader from '@/components/AppLoader';
import { isDev, loadPage } from '@/helpers';
import { useSelector } from '@/redux/helper';

const LandingRedirect = () => {
  const logged = useSelector((state) => state.user.logged);
  const sessionChecked = useSelector((state) => state.user.sessionChecked);

  useEffect(() => {
    if (!sessionChecked) {
      return;
    }
    if (!logged) {
      loadPage(isDev() ? `${window.location.origin}/login` : 'https://zoolife.tv');
    }
  }, [logged, sessionChecked]);

  return <AppLoader />;
};

export default LandingRedirect;
