import { CLOSE_TAKE_SNAPSHOT_MODAL, SHOW_TAKE_SNAPSHOT_MODAL } from './types';

const initialState = { isOpen: false };

export default (state = initialState, { type }) => {
  if (type === SHOW_TAKE_SNAPSHOT_MODAL) {
    return { ...state, isOpen: true };
  }

  if (type === CLOSE_TAKE_SNAPSHOT_MODAL) {
    return { ...state, isOpen: false };
  }

  return state;
};
