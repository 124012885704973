import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MembersOnlyWarningModalState } from './types';

const INITIAL_STATE: MembersOnlyWarningModalState = {};

const membersOnlyWarningModalSlice = createSlice({
  name: 'membersOnlyWarningModal',
  initialState: INITIAL_STATE,
  reducers: {
    setMembersOnlyWarningModalState: (state, action: PayloadAction<MembersOnlyWarningModalState | null>) => {
      return action.payload || INITIAL_STATE;
    },
  },
});

export default membersOnlyWarningModalSlice;
