import { combineReducers } from '@reduxjs/toolkit';

import shareModalSlice from '@/components/ShareModal/shareModalSlice';

import habitatAlbumSlice from './components/Album/slice';
import CameraControlModal from './components/CameraControlModal/reducer';
import habitatCardsMobileSlice from './components/CardTabs/Mobile/slice';
import cards from './components/CardTabs/reducer';
import scheduleModal from './components/ScheduleModal/reducer';
import takeSnapshotModal from './components/TakeSnapshotModal/reducer';
import trimVideoModal from './components/TrimVideoModal/reducer';
import habitatInfo from './reducer/index';

export default combineReducers({
  habitatInfo,
  cards,
  // NOTE: we didn't use the slice name here because we want to distinguish it from the album slice
  album: habitatAlbumSlice.reducer,
  mobileCards: habitatCardsMobileSlice.reducer,
  [shareModalSlice.name]: shareModalSlice.reducer,
  scheduleModal,
  CameraControlModal,
  takeSnapshotModal,
  trimVideoModal,
});
