import { trackMP } from '@/shared/mp';

import { isFreeUser } from '../components/Authorize';

/**
 * Checks the local storage for an ODTP (One-Time Password) parameter.
 *
 * @template T
 * @param {T} odtpParam - The ODTP parameter to check.
 * @return {T|null} - The ODTP parameter if it exists and meets the conditions, otherwise null.
 */
const checkLocalStorageForODTP = (odtpParam) => {
  try {
    if (odtpParam) {
      if (!isFreeUser()) {
        trackMP('upgrade-to-ODTP-rejected', {
          reason: 'already has an active subscription',
          rejectionPlace: 'front-end',
        });
        return null;
      }
      const storedValue = localStorage.getItem('odtp');
      if (storedValue) {
        // const parsedValue = JSON.parse(storedValue);
        // we have access to parsedValue.productId and parsedValue.timestamp for the future use
        trackMP('upgrade-to-ODTP-rejected', {
          reason: 'already used ODTP based on localstorage record',
          rejectionPlace: 'front-end',
        });
        return null;
      }
      return odtpParam;
    }
    return null;
  } catch (error) {
    console.error('Unable to access localStorage:', error);
    return odtpParam;
  }
};

export default checkLocalStorageForODTP;
