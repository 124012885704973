import { TypedUseSelectorHook, useSelector as reduxSelector, useStore as useReduxStore } from 'react-redux';
import { Store } from 'redux';

import { AppOnboardingModalState } from '@/components/AppOnboarding/redux/slice';
import { MembersOnlyWarningModalState } from '@/components/MembersOnlyWarningModal/redux/types';
import { TermsState } from '@/components/TermsAndConditions/redux/types';
import { HabitatListItem } from '@/queries/habitats/types';
import type { HabitatCardsMobileStates } from '@/routes/habitat/components/CardTabs/Mobile/slice';

import { DeviceSettingsState } from './slices/deviceSettings/types';
import MyAlbumState from './slices/myAlbum/types';
import { PlansState } from './slices/plans/types';
import { RemoteConfigState } from './slices/remoteConfig/types';
import { SettingsState } from './slices/settings/types';
import { UserState } from './slices/user/types';

export type AppState = {
  user: UserState;
  settings: SettingsState;
  deviceSettings: DeviceSettingsState;
  plans: PlansState;
  myAlbum: MyAlbumState;
  remoteConfig: RemoteConfigState;
  habitat: {
    mobileCards: HabitatCardsMobileStates;
    [key: string]: any;
  };
  modals: {
    membersOnlyWarningModal: MembersOnlyWarningModalState;
    appOnboardingModal: AppOnboardingModalState;
    terms: TermsState;
    [key: string]: any;
  };

  // type only slice, not yet migrated
  allHabitats: HabitatListItem[];

  // others non migrated slices
  [slice: string]: any;
};

export const useSelector: TypedUseSelectorHook<AppState> = reduxSelector;
export const useStore: () => Store<AppState> = useReduxStore;
