/* eslint-disable no-param-reassign */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type MyAlbumState from '@/redux/slices/myAlbum/types';

interface HabitatAlbumState {
  total?: number;
  list: MyAlbumState['album']['list'];
}

const initialState: HabitatAlbumState = Object.freeze({
  list: [],
});

const habitatAlbumSlice = createSlice({
  name: 'habitatAlbum',
  initialState,
  reducers: {
    setAlbumData: (state, { payload }: PayloadAction<HabitatAlbumState>) => {
      return payload;
    },
    appendAlbumData: (state, { payload }: PayloadAction<HabitatAlbumState>) => {
      state.list = [...state.list, ...payload.list];
      state.total = payload.total;
    },
    updateHabitatAlbumData: (state, { payload }: PayloadAction<{ id: string; data: any }>) => {
      state.list = state.list.map((media) => (media._id === payload.id ? { ...media, ...payload.data } : media));
    },
    changeContentVisibility: (state, { payload }: PayloadAction<{ mediaId: string; action: 'hide' | 'unhide' }>) => {
      const disabled = payload.action === 'hide';
      state.list = state.list.map((media) => (media._id === payload.mediaId ? { ...media, disabled } : media));
    },
    removeMediaFromAlbum: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.list = state.list.filter((media) => media._id !== payload.id);
    },
    addTagToContent: (state, { payload }: PayloadAction<{ contentId: string; tag: string; userId: string }>) => {
      state.list = state.list.map((content) => {
        if (content._id === payload.contentId) {
          const existingTag = content.tags?.find((t) => t.tag === payload.tag);

          if (existingTag) {
            return {
              ...content,
              tags: content.tags.map((t) => (t.tag === payload.tag ? { ...t, users: [...t.users, payload.userId] } : t)),
            };
          }

          return {
            ...content,
            tags: [...(content.tags || []), { tag: payload.tag, users: [payload.userId] }],
          };
        }
        return content;
      });
    },
    removeTagFromContent: (state, { payload }: PayloadAction<{ contentId: string; tag: string; userId: string }>) => {
      state.list = state.list.map((content) =>
        content._id === payload.contentId
          ? {
              ...content,
              tags: content.tags.map((t) =>
                t.tag === payload.tag ? { ...t, users: t.users.filter((id) => id !== payload.userId) } : t,
              ),
            }
          : content,
      );
    },
  },
});

export default habitatAlbumSlice;
